<template>
  <ProductForm></ProductForm>
</template>

<script>
import ProductForm from "./ProductForm";
export default {
  name: "Create",
  components: {ProductForm}
}
</script>

<style scoped>

</style>